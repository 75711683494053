import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { BannerNotificationContainer, Header, NotificationContainer, PageWrapper } from 'components/ui'
import { BillingContextProvider } from 'context/Billing/BillingContext'
import { LanguageContextProvider } from 'context/Language/LanguageContext'
import { WidgetGlobalStyle } from 'App.styles'

import { ErrorBoundary } from '@rg/common'
import { Loading } from '@rg/patternlab'

export const App = () => {
    const isPricingPageRoute = useRouteMatch(['/pricing', '/pricing-widget'])

    const PricingPage = lazy(() =>
        import('./pages/PricingPage/PricingPage').then((module) => ({
            default: module.PricingPage,
        }))
    )
    const RegisterPage = lazy(() =>
        import('./pages/RegisterPage/RegisterPage').then((module) => ({
            default: module.RegisterPage,
        }))
    )
    const ConfirmEmailPage = lazy(() =>
        import('./pages/ConfirmEmailPage/ConfirmEmailPage').then((module) => ({
            default: module.ConfirmEmailPage,
        }))
    )
    const AuthPage = lazy(() =>
        import('./pages/AuthPage/AuthPage').then((module) => ({
            default: module.AuthPage,
        }))
    )
    const DecisionPage = lazy(() =>
        import('./pages/DecisionPage/DecisionPage').then((module) => ({
            default: module.DecisionPage,
        }))
    )
    const BillingDetailsPage = lazy(() =>
        import('./pages/BillingDetailsPage/BillingDetailsPage').then((module) => ({
            default: module.BillingDetailsPage,
        }))
    )
    const KYCPage = lazy(() =>
        import('./pages/KYCPage/KYCPage').then((module) => ({
            default: module.KYCPage,
        }))
    )
    const SummaryPage = lazy(() =>
        import('./pages/SummaryPage/SummaryPage').then((module) => ({
            default: module.SummaryPage,
        }))
    )
    const SuccessPage = lazy(() =>
        import('./pages/SuccessPage/SuccessPage').then((module) => ({
            default: module.SuccessPage,
        }))
    )

    const CookiePolicyPage = lazy(() =>
        import('./pages/CookiePolicyPage/CookiePolicyPage').then((module) => ({
            default: module.CookiePolicyPage,
        }))
    )

    return (
        <ErrorBoundary errorBoundaryLocation="packageRoot" packageName="rg-rarebreed">
            <BillingContextProvider>
                <LanguageContextProvider>
                    <Header />
                    {isPricingPageRoute && <WidgetGlobalStyle />}
                    <BannerNotificationContainer />
                    <NotificationContainer />
                    <PageWrapper hasPaddingTop={!isPricingPageRoute}>
                        <Suspense fallback={<Loading animationType="colorful" size="lg" position="absolute" />}>
                            <Switch>
                                <Route path={['/pricing', '/pricing-widget']} component={PricingPage} />
                                <Route path="/sign-up" component={RegisterPage} />
                                <Route path="/confirm-email" component={ConfirmEmailPage} />
                                <Route path="/auth" component={AuthPage} />
                                <Route path="/verify" component={AuthPage} />
                                <Route path="/decision" component={DecisionPage} />
                                <Route path="/billing-details" component={BillingDetailsPage} />
                                <Route path="/kyc" component={KYCPage} />
                                <Route path="/summary" component={SummaryPage} />
                                <Route path="/success" component={SuccessPage} />
                                <Route path="/cookie-policy" component={CookiePolicyPage} />
                                <Redirect to="/sign-up" />
                            </Switch>
                        </Suspense>
                    </PageWrapper>
                </LanguageContextProvider>
            </BillingContextProvider>
        </ErrorBoundary>
    )
}
